<template>
  <b-container fluid>
    <ValidationObserver ref="serviceObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6" :label="$t('Name')" label-for="name">
                <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 || nameIsExists == true ? ' is-invalid' : '')" v-model="model.name " type="text"></b-form-input>
                  <b-form-invalid-feedback> {{ nameIsExists == true ? $t('ThisServiceGroupNameAlreadyExists') : $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('RepeatInterval')" label-for="repeatInterval">
                <b-form-input class="form-control"
                              type="number"
                              v-model="model.repeatInterval"
                              min="0">
                </b-form-input>
              </b-form-group>

              <b-form-group class="col-md-12" :label="$t('ServiceLengthMinute')" label-for="serviceLengthMinute">
                <b-form-input class="form-control"
                              type="number"
                              v-model="model.serviceLengthMinute"
                              min="0">
                </b-form-input>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col-md-6" :label="$t('ServiceGroup')" label-for="serviceGroup">
                <ValidationProvider :name="$t('ServiceGroup')" rules="required" v-slot="{ errors }">
                  <v-select v-model="model.serviceGroupID"
                            :reduce="s => s.id" label="name"
                            :options="serviceGroupList"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('Color')" label-for="color">
                <v-select transition="" v-model="model.color"
                          :reduce="s => s.id"
                          :options="colorList" label="name">
                <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              </b-form-group>
            </b-row>

          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6" :label="$t('Rate')" label-for="currency">
                <ValidationProvider :name="$t('Rate')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.currencyID"
                            :reduce="s => s.currencyId" label="symbol"
                            :options="currencyList"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('Price')" label-for="price">
                <ValidationProvider :name="$t('Price')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.price" @keypress="$root.isNumber"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('PriceWithCard')" label-for="priceWithCard">
                <ValidationProvider :name="$t('PriceWithCard')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.priceWithCard" @keypress="$root.isNumber"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <!--<b-form-group class="col-md-4" :label="$t('InvoiceName')" label-for="invoiceName">
                <b-form-input v-model="model.invoiceName"></b-form-input>
              </b-form-group>-->

              <b-form-group class="col-md-6" :label="$t('TaxRate')" label-for="vatPercentage">
                <b-form-input v-model="model.vatPercentage" :placeholder="$t('Example18')" @keypress="$root.isNumber"></b-form-input> <!--keypress!!-->
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewService"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import commonServices from '../../../services/common'
  import services from '../../../services/service'

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'NewService',
    components: {
      ModalFooterButton
    },
    props: {
      serviceId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          serviceGroupID: '',
          color: '#4B77BE',
          repeatInterval: '',
          serviceLengthMinute: '',
          currencyID: 1,
          price: '',
          priceWithCard: '',
          vatPercentage: '',
          invoiceName: ''

        },
        serviceGroupList: [],
        colorList: [],
        currencyList: [],
        nameIsExists: false,
        isSubmitting: false
      }
    },
    methods: {
      getColors() {
        commonServices.getColors().then((response) => {
          if (response) {
            this.colorList = response;
          }
        })
      },
      async submitNewService() {
        const isValid = await this.$refs.serviceObserver.validate();
        if (!isValid || this.nameIsExists == true) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.isSubmitting = true;

        services.submitNewService(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getCurrencyList() {
        commonServices.getCurrencyList().then((response) => {
          if (response) {
            this.currencyList = response;
          }
        })
      },
      getServiceGroup() {
        services.getServiceGroup().then((response) => {
          if (response) {
            this.serviceGroupList = response;
          }
        })
      },
      getService() {
        services.getService(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      getServiceNameIsExists() {
        services.getServiceNameIsExists(this.model.id, this.model.name)
          .then((response) => {
            this.nameIsExists = response;
          })
      },
    },
    watch: {
      'model.name': function (name) {
        if (name && name.length > 2)
          this.getServiceNameIsExists();
      },
      'model.vatPercentage': function (val) {
        if (val)
          this.model.vatPercentage = val.toString().replace(',', '.');
      }
    },
    mounted() {
      if (this.serviceId && this.serviceId.length > 0) {
        this.model.id = this.serviceId;
        this.getService();
      }
      this.getColors();
      this.getServiceGroup();
      this.getCurrencyList();
    }
  }
</script>
